<template>
  <div class="app-card">
    <div>
      <div>
          <div v-if="!isAnnouncementEdit" v-html="announcement"></div>
          <div v-else>
            <div style="height:500px;">
                <quill-editor
                  style="height:440px;"
                  v-model="announcement"
                  :options="{placeholder: '请填写内容', ...editerOptions}"
                />
              </div>
          </div>
        </div>
        <div style="text-align: center;padding-top:60rpx;">
          <div v-if="!isAnnouncementEdit">
            <a-button type="primary" @click="isAnnouncementEdit = true">
              {{announcement ? '编辑' : '编写'}}
            </a-button>
          </div>
          <div v-else>
            <a-button type="primary" @click="announcementEdit">
              确定
            </a-button>
          </div>
        </div>
    </div>

    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import { Announcement } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"type":"","title":""}'

const columns = [
  {
    title: '产品类型',
    dataIndex: 'type',
    width: 150,
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '标题',
    dataIndex: 'title',
    width: 150,
  },
  {
    title: '审核时间',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    scopedSlots: { customRender: 'action' },
  }
]

export default {
  name: 'RateEnvironmentProtection',
  mixins: [aliveVM, mixinTableList],
  async mounted () {
    const baseColumns = []
    this.$set(this, 'columns', JSON.parse(JSON.stringify(columns)))
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    await this.getAnnouncementDetail()
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      moment,
      searchParams: {},
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      columns: [],
      baseColumns: [],
      // other
      announcement: '',
      isAnnouncementEdit: false,
      editerOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["link"],
            ["clean"], //清除字体样式
            ["image"], //上传图片、上传视频
          ],
        },
        theme: "snow",
      },
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', {})
      
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', {})
      
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      let searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'startAt' || k === 'endAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      if (licia.isEmpty(searchParams)) {
        searchParams = {}
      }
      
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    async getAnnouncementDetail() {
      const apiRes = await this.$axios.get(`${Announcement}/GetDetail?type=Finance`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      this.announcement = apiRes.data.content;
    },
    async announcementEdit() {
      if(!this.announcement) {
        this.$message.error("请填写内容");
        return;
      }
      const apiRes = await this.$axios
          .post(`${Announcement}/EditRateFinance`, {
            content: this.announcement
          })
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
      if (!apiRes.status) {
        this.announcement = "";
            this.$message.error(apiRes.message);
            this.sending = false;
            return;
          }
      this.isAnnouncementEdit = false
    },
  }
}
</script>

<style>

</style>